import React, { useState } from "react";
import "./ContactUs1.css";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    education: "",
    skills: "",
    interest: "",
    resumeLink: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission status
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the form is submitted successfully

  const toggleForm = () => {
    setShowForm(!showForm);
    setIsSubmitted(false); // Reset submission state when form is toggled
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start the submitting state

    emailjs
      .sendForm(
        "service_e19r6u5",
        "template_hkf675u",
        e.target,
        "MjabMJpliFt8sJYwZ"
      )
      .then((result) => {
        console.log("Email sent successfully:", result.text);
        setIsSubmitted(true); // Set submission success state
        setFormData({
          name: "",
          email: "",
          phone: "",
          position: "",
          education: "",
          skills: "",
          interest: "",
          resumeLink: "",
        });
        setIsSubmitting(false); // Reset submitting state
        setTimeout(() => toggleForm(), 2000); // Automatically close form after 2 seconds
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setIsSubmitting(false); // Reset submitting state if there's an error
      });
  };

  return (
    <>
      <div>
        <button className="btn-4" onClick={toggleForm}>
          Contact Us
        </button>

        <div
          className={`popup-background ${showForm ? "active" : "inactive"}`}
        ></div>

        {showForm && (
          <div className="popup">
            {isSubmitted ? (
              <div className="submitted-message">
                <p>Submitted successfully! Please wait for a response.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
                <textarea
                  name="interest"
                  value={formData.interest}
                  onChange={handleChange}
                  placeholder="Write your query"
                  required
                />
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            )}
            <button className="close-button" onClick={toggleForm}>
              Close
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactUs;
