import React from "react";
import Paragraph from "../../ExternalCom/Paragraph/Paragraph";
import "./Products.css";
import Images12 from "../../Images/newimg12.jpeg";
import Images13 from "../../Images/newimg13.jpeg";
import Images14 from "../../Images/4.png";
import Images15 from "../../Images/1.png";
import Images16 from "../../Images/3.png";
import Images17 from "../../Images/2.png";

const Products = () => {
  return (
    <section className="section-products">
      <div className="content-wrapper">
        <div className="img-triangle1">
          <img src={Images14} alt="" />
        </div>
        <div className="img-triangle2">
          <img src={Images15} alt="" />
        </div>
        <div className="img-triangle3">
          <img src={Images16} alt="" />
        </div>
        <div className="img-triangle4">
          <img src={Images17} alt="" />
        </div>
        <div className="image-container">
          <div className="image-wrapper">
            <img src={Images13} alt="Product" />
          </div>
        </div>
        <div className="text-container">
          <h2 className="site-name">
            my<span style={{ color: "black" }}>HR</span>dept.in
          </h2>
          <div className="paragraph-wrapper">
            <Paragraph className="paragraph-text">
              • At{" "}
              <span style={{ color: "#0086D3" }}>
                <a
                  href="https://myhrdept.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-myhr"
                >
                  myhrdept.in
                </a>
              </span>{" "}
              we understand the unique challenges of <br />
              modern HR management.
            </Paragraph>
          </div>
          <div className="paragraph-wrapper">
            <Paragraph className="paragraph-text">
              • That's why we're committed to providing you with a
              user-friendly, feature-rich platform that empowers you to focus on
              what matters most – your people
            </Paragraph>
          </div>
          <div className="paragraph-wrapper">
            <Paragraph className="paragraph-text">
              • Experience the future of HR management with myhrdept.in today.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="image-container1">
        <div className="image-wrapper1">
          <img src={Images12} alt="ProPalz" />
        </div>
        <h4 className="site-name2">
          <span style={{ color: "blue" }}>
            <a
              href="https://ProPalz.in"
              target="_blank"
              rel="noopener noreferrer"
              className="link-myhr"
            >
              ProPalz.in
            </a>
          </span>
        </h4>
        <div className="paragraph-wrapper1">
          <Paragraph className="paragraph-text">
            Your Ultimate Socializing <br />
            And Professional Networking Platform
          </Paragraph>
        </div>
      </div>
    </section>
  );
};

export default Products;
