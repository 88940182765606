import React, { useEffect, useState, useMemo } from "react";
// import Typed from 'typed.js';
import "./Herobody.css";
import "./Bluesection.css";
// import { Link } from "react-router-dom";
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "./MySlider.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./Counter.css";
import Images4 from "../../Images/newimg4.png";
// import Images11 from "../../Images/img-1.png";
// import Images12 from "../../Images/img-2.png";
// import Images13 from "../../Images/img-3.png";
// import Images14 from "../../Images/img-4.png";
// import Images15 from "../../Images/img-5.png";
export default function Home() {
  const [counterOn, setCounterOn] = useState(false);
  // const textItems = ['Minimising Cost', 'Increasing Productivity', 'Reducing Risk', 'Sustaining Growth'];
  const textItems = useMemo(
    () => [
      "Minimising Cost",
      "Increasing Productivity",
      "Reducing Risk",
      "Sustaining Growth",
    ],
    []
  );
  const [index, setIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const text = textItems[index];

    if (isTyping) {
      const interval = setInterval(() => {
        if (charIndex < text.length) {
          setDisplayText((prevText) => prevText + text[charIndex]);
          setCharIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsTyping(false);
          clearInterval(interval);
        }
      }, 100);

      return () => clearInterval(interval);
    } else {
      const timeout = setTimeout(() => {
        setDisplayText("");
        setCharIndex(0);
        setIndex((prevIndex) => (prevIndex + 1) % textItems.length);
        setIsTyping(true);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [index, charIndex, textItems, isTyping]);
  // const typedRef=useRef(null)
  // useEffect(() =>{
  //     const options={
  //     strings:[
  //         "Increasing Productivity",
  //         "Random Text XYZ-1",
  //         " Another Random Text XYZ-1",
  //     ],
  //     typeSpeed: 50,
  //     backSpeed:50,
  //     loop:true,
  // };
  // const typed=new Typed(typedRef.current,options)
  // return ()=>{
  //     typed.destroy()
  // }
  // },[])
  return (
    <>
      <section>
        <div id="up-showcase">
          {/* <div id="jobtitle">
                        <p >HR Labs, where human-centered HR consulting meets
                            transformative solutions.</p>
                    </div> */}
          <div id="showcase">
            <div className="starthead">
              <div className="introbox">
                <h2>Helping Startups, SMEs and brands in : </h2>
              </div>

              {/* <div className="introbox">
                    <Heading level={5} className="fw">Helping Startups, SMEs and brands in :</Heading>
                </div> */}

              <div className="text-sliderbox">
                <strong className="title">{displayText}</strong>
              </div>
            </div>
            <div className="homeimgbox">
              <div className="home-cont">
                HR Labs Where human-centered HR <br />
                consulting meets transformative solutions
              </div>
              <div className="home-image2">
                <img src={Images4} alt=" " width="300" height="300" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div id="down-photo">
          <div id="iconsfi">
            <div>
              <Link to="/Consulting">
                <img src={Images11} alt="" />
              </Link>
            </div>
            <div>
              <Link to="/Consulting">
                <img src={Images12} alt="" />
              </Link>
            </div>
            <div>
              <Link to="/Consulting">
                <img src={Images13} alt="" />{" "}
              </Link>
            </div>
            <div>
              <Link to="/Consulting">
                <img src={Images14} alt="" />{" "}
              </Link>
            </div>
            <div>
              <a href="/" target="_blank">
                <img src={Images15} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="main-depo">
          <div className="depo">
            <div className="head-depo">
              <h1>Our Department Suite</h1>
            </div>
            <div id="para1">
              <p>
                Have invested over 18+ Years in Learning, understanding,
                implementing & turning non-performing organizations to a
                profitable business.
              </p>
            </div>
            <div id="para2">
              <p>
                On Mission to Help 1,00,000 Startups with Effective People,
                Process & Performance Management System.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="slider">
          <div id="slider-heading">
            <h1>Have been featured in:</h1>
          </div>
          <div id="img-slider">
            <Splide
              id="container"
              options={{
                type: "loop",
                perPage: 5,
                perMove: 5,
                arrows: false,
              }}
            >
              <SplideSlide>
                <div className="slide-image-container">
                  <img src="./images/outlook1.png" alt="" />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide-image-container">
                  <img src="./images/logob.png" alt="" />
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="slide-image-container">
                  <img src="./images/zee.png" alt="" />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide-image-container">
                  <img src="./images/theweek1.png" alt="" />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="slide-image-container" id="inc">
                  <img src="./images/wireindia1.png" alt="" />
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
      <section>
        <div id="main-counter">
          <div id="counter">
            <div id="counter-heading">
              <p>Crafted in India | Designed for Global Excellence.</p>
            </div>
            <div id="counter-page">
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div>
                    <h2>
                      {counterOn && (
                        <CountUp start={0} end={50} duration={2} Delay={0} />
                      )}
                      +
                    </h2>
                    <p>Companies</p>
                  </div>
                </ScrollTrigger>
              </div>
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div>
                    <h2>
                      {counterOn && (
                        <CountUp start={0} end={6} duration={2} Delay={0} />
                      )}
                      +
                    </h2>
                    <p>States</p>
                  </div>
                </ScrollTrigger>
              </div>
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div>
                    <h2>
                      {counterOn && (
                        <CountUp start={0} end={90} duration={2} Delay={0} />
                      )}
                      +
                    </h2>
                    <p>Projects</p>
                  </div>
                </ScrollTrigger>
              </div>
              <div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div>
                    <h2>4.8/5</h2>
                    <p>Ratings</p>
                  </div>
                </ScrollTrigger>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
