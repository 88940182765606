// import React, { useState } from 'react';
// import './ContactUs.css';
// const ContactUs = () => {
//   const [showPopup, setShowPopup] = useState(false);

//   const togglePopup = () => {
//     setShowPopup(!showPopup);
//   };

//   return (
//     <div>
//       <button className="contact-us-button" onClick={togglePopup}>Contact Us</button>
//       {showPopup && (
//         <div className="popup">
//           <button onClick={() => window.open('mailto:contact@example.com')}>Get in touch through email</button>
//           <button onClick={() => window.open('https://wa.me/1234567890')}>Get in touch through WhatsApp</button>
//           <button onClick={() => window.open('https://example.com')}>Get in touch through website</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ContactUs;







// import React, { useState } from 'react';

// import './ContactUs.css';

// const ContactUs = () => {
//   const [showPopup, setShowPopup] = useState(false);

//   const togglePopup = () => {
//     setShowPopup(!showPopup);
//   };

//   return (
//     <>
//     <div>
//       <button className="button-3" onClick={togglePopup}>Contact Us</button>
      
//       <div className={`popup-background ${showPopup ? 'active' : 'inactive'}`}></div>

//       {showPopup && (
//         <div className="popup">
          
//           <div className="contact-method">
//             <img src="./images/email-icon.png" alt="Email"/>
//             <button className="popup-button" onClick={() => window.open('mailto:Muthu.kumar@myhrdept.in')}>Get in touch through email</button>
//           </div>
//           <div className="contact-method">
//             <img src="./images/pngimg.png"  alt="WhatsApp"  />
//             <button className="popup-button" onClick={() => window.open('https://wa.me/9742194666')}>Get in touch through WhatsApp</button>
//           </div>
//           <div className="contact-method">
//             <img src="./images/logo-website.png"  alt="Website" />
//             <button className="popup-button" onClick={() => window.open('https://myhrdept.in/')}>Get in touch through website</button>
//           </div>
//           <button className="close-button" onClick={togglePopup}>Close</button>
//         </div>
//       )}
//     </div>
    
//     </>
//   );
// };

// export default ContactUs;





import React, { useState } from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
    education: '',
    skills: '',
    interest: '',
    resumeLink: ''
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e19r6u5', 'template_hkf675u', e.target, 'MjabMJpliFt8sJYwZ')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setFormData({
          name: '',
          email: '',
          phone: '',
          position: '',
          education: '',
          skills: '',
          interest: '',
          resumeLink: ''
        });
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      });
  };

  return (
    <>
      <div>
        <button className="button-3" onClick={toggleForm}>Contact Us</button>

        <div className={`popup-background ${showForm ? 'active' : 'inactive'}`}></div>

        {showForm && (
          <div className="popup">
            <form onSubmit={handleSubmit}>
              <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
              <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
              {/* <input type="text" name="position" value={formData.position} onChange={handleChange} placeholder="Position" required /> */}
              {/* <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Your Phone" required /> */}
              {/* <input type="text" name="education" value={formData.education} onChange={handleChange} placeholder="Your Education" required /> */}
              {/* <textarea name="skills" value={formData.skills} onChange={handleChange} placeholder="Your Skills" required /> */}
              <textarea name="interest" value={formData.interest} onChange={handleChange} placeholder="Write your query" required />
              {/* <input type="text" name="resumeLink" value={formData.resumeLink} onChange={handleChange} placeholder="Resume Link" required /> */}
              <button type="submit">Submit</button>
            </form>
            <button className="close-button" onClick={toggleForm}>Close</button>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactUs;
