import React, { useState, useRef } from "react";
import styles from "./Careers.module.css";
import careerImg_2 from "../../Images/carrerImg_2.jpg";
import emailjs from "emailjs-com";

const Popup = ({ message, onClose }) => {
  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <p>{message}</p>
        <button onClick={onClose} className={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  );
};

const CareerPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    education: "",
    position: "",
    skills: "",
    interest: "",
    resumeLink: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const formSectionRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_e19r6u5",
        "template_x2h66fg",
        e.target,
        "MjabMJpliFt8sJYwZ"
      )
      .then((result) => {
        console.log("Email sent successfully:", result.text);

        // Reset the form
        setFormData({
          name: "",
          email: "",
          phone: "",
          education: "",
          skills: "",
          interest: "",
          resumeLink: "",
        });

        setPopupMessage("Form submitted successfully!");
        setShowPopup(true);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setPopupMessage("Something went wrong. Please try again.");
        setShowPopup(true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const scrollToForm = () => {
    // Scroll to the form section
    formSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.careerPage}>
      {showPopup && (
        <Popup message={popupMessage} onClose={() => setShowPopup(false)} />
      )}

      {/* //& Hero Section */}
      <section className={styles.heroSection}>
        <div className={styles.content}>
          <h1>
            Join the HR Labs CSR Internship Program:
            <br />
            Your Gateway to a Bright Career in Software <br />
            Engineering - <span>Launch it with HRLabs</span>
          </h1>
          <p>
            Get hands-on experience, earn a certificate, and transition into a
            paid role as a Junior Software Engineer.
          </p>
          <div className={styles.buttons}>
            <button className={styles.applyButton} onClick={scrollToForm}>
              Apply Now
            </button>
            <button className={styles.learnButton}>Learn More</button>
          </div>
        </div>
      </section>

      {/* //& Program Highlight Section */}
      <section className={styles.programHighlight}>
        <p>
          This unique program is your stepping stone to <br /> a rewarding
          career in software engineering – <br /> Here’s why you should join:
        </p>
      </section>

      {/* //& Benefits Sections */}
      <div className={styles.experienceSection}>
        <div className={styles.experienceMainBox}>
          <div className={styles.experienceBox}>
            <h1 className={styles.experienceTitle}>
              1. Gain Real-World Experience -
            </h1>
            <div className={styles.experienceContent}>
              <div className={styles.experienceBox1}>
                <h3 className={styles.experienceSubtitle}>Live Projects</h3>
                <p className={styles.experienceDescription}>
                  Work on real-world projects guided by industry experts.
                </p>
              </div>
              <div className={styles.experienceBox1}>
                <h3 className={styles.experienceSubtitle}>Sharpen Skills</h3>
                <p className={styles.experienceDescription}>
                  This isn’t just an internship; it’s a chance to solve actual
                  business challenges and sharpen your technical expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //& certicate section */}
      <div className={styles.certificateSection}>
        <div className={styles.certificateBox}>
          <h1 className={styles.certificateTitle}>
            2. Earn an Internship Certificate
          </h1>
          <p className={styles.description}>
            After completing the 4-6 month unpaid internship, you’ll receive a{" "}
            <span className={styles.highlight}>
              certificate to validate your contributions and enhance your
              resume.
            </span>
          </p>
        </div>
      </div>
      {/*  //& transistion section */}
      <div className={styles.transitionSection}>
        <h1 className={styles.title}>3. Seamless Transition to Paid Roles</h1>
        <div className={styles.contentTransition}>
          <div className={styles.triangleContainer}>
            <div className={styles.triangle}>
              <div className={styles.level} data-level="1">
                1
              </div>
              <div className={styles.level} data-level="2">
                3
              </div>
              <div className={styles.level} data-level="3">
                2
              </div>
            </div>
          </div>
          <div className={styles.textContainer}>
            <div className={`${styles.textHeading} ${styles.leftText1}`}>
              <h2>Junior Software Engineer</h2>
            </div>
            <div className={`${styles.textHeading2} ${styles.leftText2}`}>
              <h2>Trainee</h2>
              <p>
                6 months, with a stipend - Upon qualifying, you will be taken as
                Jr. Engineer with a decent salary.
              </p>
            </div>
            <div className={`${styles.textHeading3} ${styles.leftText3}`}>
              <h2>Internship</h2>
              <p>
                4-6 months, unpaid - Upon qualifying, you will be taken as
                Trainee with a stipend.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* // & card 4 Sections */}
      <div className={styles.cardContainer}>
        <img
          src={careerImg_2}
          alt="Background"
          className={styles.cardBackgroundImage}
        />
        <div className={styles.cardTextBox}>
          <h2 className={styles.cardTitle}>
            4. Secure Your Future as a Junior Software Engineer
          </h2>
          <p className={styles.cardDescription}>
            Upon successfully completing your probation, you’ll join HR Labs as
            a <strong>Junior Software Engineer</strong>, enjoying a decent
            salary on our company payroll.
          </p>
        </div>
      </div>
      {/* //& card5 section */}
      <div className={styles.containerCard5}>
        <div className={styles.overlay}>
          <h2 className={styles.titleCard5}>
            5. Flexible Work-from-Home Opportunity
          </h2>
          <p className={styles.subtitle}>
            Work remotely, learn comfortably, and balance your personal and
            professional life effectively.
          </p>
        </div>
      </div>
      {/* //& card6 section */}
      <div className={styles.marketSkillsContainer}>
        <div className={styles.marketSkillsBox}>
          <h2 className={styles.marketSkillsTitle}>
            6. Market-Driven Skills Development
          </h2>
          <div className={styles.skillCards}>
            <div className={styles.skillCard}>
              <h3 className={styles.skillCardTitle}>Industry-Relevant Tools</h3>
              <p className={styles.skillCardDescription}>
                Learn the latest tools, frameworks, and methodologies.
              </p>
            </div>
            <div className={styles.skillCard}>
              <h3 className={styles.skillCardTitle}>Stay Ahead</h3>
              <p className={styles.skillCardDescription}>
                Develop skills that employers demand.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* //& Why Choose HRLabs */}
      <div className={styles.hrlabsContainer}>
        <div className={styles.hrlabsCard}>
          <h2 className={styles.hrlabsTitle}>Why Choose HRLabs?</h2>
          <div className={styles.hrlabsFeatures}>
            <div className={styles.hrlabsFeature}>
              <div className={styles.hrlabsIcon}></div>
              <div className={styles.hrlabsFeatureContent}>
                <h3 className={styles.hrlabsFeatureTitle}>
                  Supportive Learning Environment
                </h3>
                <p className={styles.hrlabsFeatureDescription}>
                  Your growth is our priority.
                </p>
              </div>
            </div>
            <div className={styles.hrlabsFeature}>
              <div className={styles.hrlabsIcon}></div>
              <div className={styles.hrlabsFeatureContent}>
                <h3 className={styles.hrlabsFeatureTitle}>
                  Cutting-Edge Technologies
                </h3>
                <p className={styles.hrlabsFeatureDescription}>
                  Exposure to the latest technologies and live projects.
                </p>
              </div>
            </div>
            <div className={styles.hrlabsFeature}>
              <div className={styles.hrlabsIcon}></div>
              <div className={styles.hrlabsFeatureContent}>
                <h3 className={styles.hrlabsFeatureTitle}>Clear Career Path</h3>
                <p className={styles.hrlabsFeatureDescription}>
                  Structured milestones lead to success.
                </p>
              </div>
            </div>
          </div>
          <p className={styles.hrlabsFooterText}>
            "Turn Your Training into Triumph with HR Labs!"
          </p>
        </div>
      </div>
      {/* Footer Section */}

      <div className={styles.futureContainer} ref={formSectionRef}>
        <div className={styles.futureContent}>
          <h1 className={styles.futureTitle}>
            Start Building Your Future Today
          </h1>
          <p className={styles.futureSubtitle}>
            Don't just dream about a successful career—start building it today!
          </p>
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="full_name" className={styles.label}>
                  Full Name
                </label>
                <input
                  type="text"
                  id="full_name"
                  name="name"
                  className={styles.formControl}
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="position" className={styles.label}>
                  Position
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  className={styles.formControl}
                  value={formData.position}
                  onChange={handleChange}
                  placeholder="Position"
                  required
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="email" className={styles.label}>
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={styles.formControl}
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="phone" className={styles.label}>
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className={styles.formControl}
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Your Phone"
                  required
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="education" className={styles.label}>
                  Education
                </label>
                <input
                  type="text"
                  id="education"
                  name="education"
                  className={styles.formControl}
                  value={formData.education}
                  onChange={handleChange}
                  placeholder="Your Education"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="resumeLink" className={styles.label}>
                  Resume Link
                </label>
                <input
                  type="text"
                  id="resumeLink"
                  name="resumeLink"
                  className={styles.formControl}
                  value={formData.resumeLink}
                  onChange={handleChange}
                  placeholder="Resume Link"
                  required
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="skills" className={styles.label}>
                  Your Skills
                </label>
                <textarea
                  name="skills"
                  id="skills"
                  className={`${styles.formControl} ${styles.formTextarea}`}
                  value={formData.skills}
                  onChange={handleChange}
                  placeholder="Your Skills"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="interest" className={styles.label}>
                  Why I'm Interested
                </label>
                <textarea
                  name="interest"
                  id="interest"
                  className={`${styles.formControl} ${styles.formTextarea}`}
                  value={formData.interest}
                  onChange={handleChange}
                  placeholder="Why I'm Interested"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>

          <p className={styles.futureDescription}>
            Join the HR Labs CSR Internship Program and take the first step
            toward becoming a software engineering professional.
          </p>
          <p className={styles.futureFooterText}>
            Let’s shape your future together!
          </p>
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
