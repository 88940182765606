import React from "react";
import styles from "./Partners.module.css";
import PartnerForm from "./PartnerForm";
import partnerImg3 from "../../Images/partnerImg_3.jpg";
import partnerImg4 from "../../Images/partnerImg_4.jpg";
import partnerImg5 from "../../Images/partnerImg_5.jpg";
import partnerImg6 from "../../Images/partnerImg_6.jpg";
// import "../../index.css";

const Partners = () => {
  return (
    <div>
      {/* //? Header Section */}
      <header className={styles.headerSection}>
        <div className={styles.container}>
          <h1>Collaborate with HR Labs - CSR Internship Program</h1>
          <p>
            Empower Your Students/Candidates with Real-World Career
            Opportunities.
          </p>
          <div className={styles.buttonGroup}>
            <button
              onClick={() => {
                document
                  .getElementById("partnerForm")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className={styles.button}
            >
              Partner with HR Labs
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("partnerForm")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className={styles.button}
            >
              Learn More about CSR Internship Program
            </button>
          </div>
        </div>
      </header>
      {/* //? About Section */}
      <section className={styles.aboutSection}>
        <div className={styles.container}>
          <div></div>
          <p>
            At HRLabs, we understand the efforts that software training
            institutes and companies put into preparing skilled candidates.
            We’re here to extend that journey by providing your candidates with
            a clear path to professional success through our{" "}
            <strong>CSR Internship Program</strong>.
          </p>
          <p>
            This program is tailored to help software trainees gain practical,
            market-ready skills, ensuring their seamless transition from
            learners to skilled professionals. By partnering with HRLabs, you’ll
            give your candidates an edge in the competitive job market.
          </p>
          <button
            onClick={() => {
              document
                .getElementById("partnerForm")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className={styles.aboutButton}
          >
            Partner for CSR Internship Program
          </button>
        </div>
      </section>
      {/* //? Why Partner Section */}
      <section className={styles.whyPartnerSectionUnique}>
        <div className={styles.backgroundOverlayUnique}>
          <div className={styles.containerUnique}>
            <h2>Why Partner with HRLabs?</h2>
            <ul className={styles.whyPartnerListUnique}>
              <li>
                <div className={styles.listBoxUnique}>
                  <span className={styles.boxIconUnique}></span>
                  <div>
                    <strong>1. Real-World Project Exposure</strong>
                    <p>
                      Your candidates will gain valuable experience by working
                      on live projects, applying their skills in real-world
                      scenarios under the guidance of experienced professionals.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.listBoxUnique}>
                  <span className={styles.boxIconUnique}></span>
                  <div>
                    <strong>2. Internship with Recognized Certification</strong>
                    <p>
                      The program includes a 4-6 month unpaid internship
                      culminating in an Experience Certificate that validates
                      their contributions and boosts their credibility in the
                      industry.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* //? Opportunities Section */}
      <section className={styles.opportunitiesSectionUnique}>
        <div className={styles.containerUnique1}>
          <h2>From Internship to Paid Opportunities</h2>
          <ul>
            <li>
              <strong>3. Transition to Trainee Role:</strong>{" "}
              <span>
                Candidates progress to a Trainee role with a stipend for 6
                months.
              </span>
            </li>
            <li>
              <strong>4. Opportunity as Junior Software Engineers:</strong>{" "}
              <span>
                Successful candidates join HR Labs as Junior Software Engineers
                with a competitive salary.
              </span>
            </li>
          </ul>
        </div>
      </section>

      {/* Flexible Work Section */}
      <section className={styles.flexibleWorkSection}>
        <div className={styles.flexibleWorkContainer}>
          {/* Image on the left */}
          <div className={styles.flexibleWorkImageWrapper}>
            <img
              src={partnerImg3}
              alt="Person Working Remotely"
              className={styles.flexibleWorkImage}
            />
          </div>

          {/* Text content on the right */}
          <div className={styles.flexibleWorkContentWrapper}>
            <h3 className={styles.flexibleWorkHeading}>
              5. Flexible Work-from-Home Setup
            </h3>
            <p className={styles.flexibleWorkParagraph}>
              <strong className={styles.flexibleWorkBoldText}>
                This program is fully remote,
              </strong>{" "}
              allowing your candidates to balance their personal lives while
              gaining invaluable professional experience.
            </p>

            <h3 className={styles.flexibleWorkHeading}>
              6. Industry-Ready Skill Development
            </h3>
            <p className={styles.flexibleWorkParagraph}>
              We emphasize{" "}
              <strong className={styles.flexibleWorkBoldText}>
                building skills that are in high demand
              </strong>{" "}
              in today’s job market, ensuring your candidates are equipped to
              excel in their careers.
            </p>

            <button
              onClick={() => {
                document
                  .getElementById("partnerForm")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className={styles.flexibleWorkCtaButton}
            >
              Let's partner with HR Labs
            </button>
          </div>
        </div>
      </section>
      {/*  //? Benefits Section */}
      <section className={styles.benefitsSection}>
        <div className={styles.benefitsContainer}>
          {/* Image on the left */}
          <div className={styles.benefitsImageWrapper}>
            <img
              src={partnerImg4}
              alt="Graduation Celebration"
              className={styles.benefitsImage}
            />
          </div>

          {/* Benefits content on the right */}
          <div className={styles.benefitsContentWrapper}>
            <h2 className={styles.benefitsHeading}>
              Benefits for Your Institute or Company
            </h2>

            <div className={styles.benefitsCards}>
              <div className={styles.benefitCard}>
                <h3 className={styles.benefitTitle}>Placement Success</h3>
                <p className={styles.benefitDescription}>
                  <strong>Strengthen your placement success rate</strong> by
                  providing your trainees with a structured career path and a
                  connection to a reputable software company.
                </p>
              </div>

              <div className={styles.benefitCard}>
                <h3 className={styles.benefitTitle}>Reputation Enhancement</h3>
                <p className={styles.benefitDescription}>
                  <strong>Build your reputation as an institute/company</strong>{" "}
                  producing job-ready candidates by providing them with
                  real-world experience and recognised certifications.
                </p>
              </div>

              <div className={styles.benefitCard}>
                <h3 className={styles.benefitTitle}>
                  For Future Placements as well
                </h3>
                <p className={styles.benefitDescription}>
                  <strong>Establish a long-term partnership</strong> with HRLabs
                  for future candidate placements.
                </p>
              </div>

              <div className={styles.benefitCard}>
                <h3 className={styles.benefitTitle}>
                  Students with Structured Career Path
                </h3>
                <p className={styles.benefitDescription}>
                  <strong>
                    Provide your students with a structured career path
                  </strong>{" "}
                  within a reputed software company.
                </p>
              </div>
            </div>

            <button
              onClick={() => {
                document
                  .getElementById("partnerForm")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className={styles.ctaButton}
            >
              I want these benefits
            </button>
          </div>
        </div>
      </section>

      {/*  //? Join Section */}
      <div className={styles.partnersContainer}>
        <div className={styles.partnersContent}>
          <h1>Join Hands with HRLabs Today</h1>
          <p>
            Secure your candidates' futures by partnering with us to give your
            students the advantage of real-world experience and a rewarding
            career path.
          </p>
          <strong>
            By working together, we can shape tomorrow’s tech leaders!
          </strong>
          <button
            onClick={() => {
              document
                .getElementById("partnerForm")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className={styles.partnersButton}
          >
            Lets work together
          </button>
        </div>
        <div className={styles.partnersImage}>
          <img src={partnerImg5} alt="Handshake" />
        </div>
      </div>
      {/* //? Testimonials Section */}
      <div
        className={styles.hrlabsUniqueSection}
        style={{ backgroundImage: `url(${partnerImg6})` }}
      >
        <div className={styles.hrlabsUniqueContent}>
          <h1 className={styles.hrlabsUniqueTitle}>
            Join hands with HRLabs today to secure your students' future!
          </h1>
          <p className={styles.hrlabsUniqueDescription}>
            HRLabs is dedicated to bridging the gap between education and
            industry, providing candidates with the{" "}
            <strong>skills, experience, Experience Letter, Stipend</strong> they
            need to thrive in today’s competitive tech landscape.
          </p>
          <button
            onClick={() => {
              document
                .getElementById("partnerForm")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className={styles.hrlabsUniqueButton}
          >
            Partner with HR Labs
          </button>
          <p className={styles.hrlabsUniqueTagline}>
            Your students' Gateway to Real-World Success!
          </p>
        </div>
      </div>

      {/* //? Footer Section */}
      <div className={styles.internshipSection}>
        <div className={styles.internshipContent}>
          <h1 className={styles.internshipTitle}>
            See what our Internship folks say:
          </h1>
          <div className={styles.internshipIcon}>
            <div className={styles.userIcon}>
              <div className={styles.head}></div>
              <div className={styles.body}></div>
            </div>
          </div>
          <h2 className={styles.internshipSubtitle}>
            Empower Your Students’ Careers with HR Labs - CSR Internship Program
            -
          </h2>
          <button
            onClick={() => {
              document
                .getElementById("partnerForm")
                .scrollIntoView({ behavior: "smooth" });
            }}
            className={styles.internshipButton}
          >
            Join us
          </button>
          <footer className={styles.internshipFooter}>
            <span className={styles.footerBrand}>HR Labs</span> - Empowering
            candidates for a successful future in tech.
          </footer>
          <PartnerForm />
        </div>
      </div>
    </div>
  );
};

export default Partners;
