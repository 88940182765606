import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../styles/Footer/footer.css';

// import {
//     AiOutlineTwitter,
//     AiFillInstagram,
//     AiFillFacebook
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";

export default function Footer() {
    return (
        <footer className="footer">
            <Container className="footer-content">
                <div id="showcase-footer">
                    <div className="footer-icons">
                        <li className="social-icons">
                            <a
                                href="https://twitter.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                            {/* <AiOutlineTwitter size={24} /> */}
                            <img src="./images/imageb.png" alt=""/>
                             </a>
                        </li>
                        <li className="social-icons">
                            <a
                                href="https://linkedin.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* <FaLinkedinIn size={24} /> */}
                                <img src="./images/Linked_icon.png" alt=""/>
                            </a>
                        </li>
                        <li className="social-icons">
                            <a
                                href="https://facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {/* <AiFillFacebook size={24} /> */}
                                <img src="./images/Facebook.png" alt=""/>
                            </a>
                        </li>
                        <li className="social-icons" id="iconinsta">
                             <a
                                href="https://instagram.com"
                                target="_blank"
                                 rel="noopener noreferrer"
                            >
                                {/* <AiFillInstagram size={24} /> */}
                                <img src="./images/png-insta.png" alt=""/>
                             </a>
                        </li>
                    </div>
                    <div className="foot-panel2" >
                        <div className="explore-section ">
                            <h1 className="text-lg">Explore</h1>
                            <ul className="list-none">
                                <li><Link to='/start-here'>Start here</Link></li>
                                <li><Link to='/blog'>Blog</Link></li>
                                <li><Link to='/about'>About Us</Link></li>
                            </ul>
                        </div>
                        <div className="information-section">
                            <h1 className="text-lg">Information</h1>
                            <ul className="list-none">
                                <li><Link to='/membership'>Membership</Link></li>
                                <li><Link to='/purchase-guide'>Purchase Guide</Link></li>
                                <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                <li><Link to='/terms-and-services'>Terms and services</Link></li>
                           </ul>
                       </div>
                   </div>
                   
                </div>
                <div className="footer-copywright" style={{marginTop:"20px"}}>
                    <h3>© hrlabs.in | All Rights Reserved</h3>
                </div>
                
            </Container>
        </footer>
    );
}
