import React, { useState } from "react";
import styles from "./PartnerForm.module.css";
import emailjs from "emailjs-com";

const PartnerForm = () => {
  const [formData, setFormData] = useState({
    industryType: "",
    organizationName: "",
    contactNumber: "",
    email: "",
    state: "",
    city: "",
    isInterested: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Track if form is submitting
  const [popupMessage, setPopupMessage] = useState(""); // Track the popup message
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Show/hide the popup

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start the "Submitting..." state

    emailjs
      .sendForm(
        "service_2q1ttri", // EmailJS service ID
        "template_43zrdhj", // EmailJS template ID
        e.target,
        "DSM_rBgDSF3ME5HHz" // EmailJS public key
      )
      .then((result) => {
        console.log("Form submitted successfully:", result.text);
        setPopupMessage("Form submitted successfully!");
        setIsPopupVisible(true); // Show success popup
        setIsSubmitting(false); // Reset button state
        // Clear the form
        setFormData({
          industryType: "",
          organizationName: "",
          contactNumber: "",
          email: "",
          state: "",
          city: "",
          isInterested: false,
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setPopupMessage("Failed to submit the form. Please try again.");
        setIsPopupVisible(true); // Show error popup
        setIsSubmitting(false); // Reset button state
      });
  };

  const closePopup = () => {
    setIsPopupVisible(false); // Hide the popup
  };

  return (
    <div id="partnerForm" className={styles.formContainer}>
      <h1 className={styles.formTitle}>Partner with HR Labs</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="industryType">
            Industry Type
          </label>
          <select
            id="industryType"
            name="industryType"
            className={styles.select}
            value={formData.industryType}
            onChange={handleChange}
            required
          >
            <option value="">Select Industry Type</option>
            <option value="training_institute">Training Institute</option>
            <option value="college">College</option>
            <option value="consultancy">Consultancy</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="organizationName">
            Organization
          </label>
          <input
            id="organizationName"
            name="organizationName"
            type="text"
            className={styles.input}
            value={formData.organizationName}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="contactNumber">
            Contact Number
          </label>
          <input
            id="contactNumber"
            name="contactNumber"
            type="tel"
            className={styles.input}
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className={styles.input}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="state">
            State
          </label>
          <input
            id="state"
            name="state"
            type="text"
            className={styles.input}
            value={formData.state}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="city">
            City
          </label>
          <input
            id="city"
            name="city"
            type="text"
            className={styles.input}
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>

        <div className={styles.checkboxGroup}>
          <input
            id="isInterested"
            name="isInterested"
            type="checkbox"
            className={styles.checkbox}
            checked={formData.isInterested}
            onChange={handleChange}
          />
          <label className={styles.checkboxLabel} htmlFor="isInterested">
            Interested in partnering with HR Labs
          </label>
        </div>

        <div className={styles.buttonWrapper}>
          <button
            type="submit"
            className={styles.submitButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>

      {/* Popup for feedback */}
      {isPopupVisible && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <p>{popupMessage}</p>
            <button className={styles.closeButton} onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerForm;
