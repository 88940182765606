import React, { useState } from "react"; // eslint-disable-next-line
import { FaSearch, FaBars, FaTimes } from "react-icons/fa"; //
import { Link } from "react-router-dom";
import hrlabLogo from "../Images/hrlab.png";
import "../styles/Header/header.css"; // Import the header CSS file
import ContactUs from "./ContactUs";
import ContactUs1 from "./ContactUs1";
export default function Header() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState(null);

  const handleNavLinkClick = (linkName) => {
    setActiveLink(linkName);
  };
  const toggleSidebar = () => {
    setShowSidebar((prevState) => !prevState); // Toggle the sidebar state
  };

  const closeSidebar = () => {
    setShowSidebar(false); // Close the sidebar
  };

  return (
    <header className="header">
      <div className="header-content">
        {/* <Link to='/'> */}
        <Link>
          <img src={hrlabLogo} alt="HR Labs Logo" className="logo" />
        </Link>
        <ul className="nav-links">
          <li>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("home")}
              className={activeLink === "home" ? "active" : ""}
            >
              Home
            </Link>
          </li>
          {/* <li><Link to='/'>Home</Link></li>
          <li><Link to='/Consulting'>Consulting</Link></li>
          <li><Link to='/Products'>Products</Link></li>
          
          <li><Link to='/Company'>Company</Link></li> */}
          <li>
            <Link
              to="/Consulting"
              onClick={() => handleNavLinkClick("consulting")}
              className={activeLink === "consulting" ? "active" : ""}
            >
              Consulting
            </Link>
          </li>
          <li>
            <Link
              to="/Products"
              onClick={() => handleNavLinkClick("products")}
              className={activeLink === "products" ? "active" : ""}
            >
              Products
            </Link>
          </li>
          <li>
            <Link
              to="/Company"
              onClick={() => handleNavLinkClick("company")}
              className={activeLink === "company" ? "active" : ""}
            >
              Company
            </Link>
          </li>
          <li>
            <Link
              to="/Careers"
              onClick={() => handleNavLinkClick("Careers")}
              className={activeLink === "Careers" ? "active" : ""}
            >
              Careers
            </Link>
          </li>
          <li>
            <Link
              to="/Partners"
              onClick={() => handleNavLinkClick("Partners")}
              className={activeLink === "Partners" ? "active" : ""}
            >
              Partners
            </Link>
          </li>
        </ul>
        {/* <form className="search-form">
          <input type="text" placeholder="Search..." className="search-input" />
          <FaSearch className="search-icon" />
        </form> */}
        <div>
          {/* <a href="/"><button className='button3' id="btn-1">Contact Us</button></a> */}
          <ContactUs className="button-3" />
        </div>
        <div className="mobile-menu">
          <button onClick={toggleSidebar}>
            {showSidebar ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>
      {showSidebar && (
        <nav className="sidebar">
          <div className="sidebar-content">
            {/* <form className="search-form1">
              <input
                type="text"
                placeholder="Search..."
                className="search-input1"
              />
              <button type="submit" className="search-icon1">
                <FaSearch />
              </button>
            </form> */}
            <button className="close-btn" onClick={closeSidebar}>
              <FaTimes />
            </button>
            <Link to="/" className="sidebar-link" onClick={closeSidebar}>
              Home
            </Link>
            <Link
              to="/Consulting"
              className="sidebar-link"
              onClick={closeSidebar}
            >
              Consulting
            </Link>
            <Link
              to="/Products"
              className="sidebar-link"
              onClick={closeSidebar}
            >
              Products
            </Link>
            {/* <Link to='/Customers' className='sidebar-link' onClick={closeSidebar}>Customers</Link> */}
            <Link to="/Company" className="sidebar-link" onClick={closeSidebar}>
              Company
            </Link>
            <Link to="/Careers" className="sidebar-link" onClick={closeSidebar}>
              Careers
            </Link>
            {/* <a href="/Company"><button className='button4' id="btn-4" onClick={closeSidebar}>Contact Us</button></a> */}
            <Link to="/Partners" onClick={closeSidebar}>
              Partners
            </Link>
            <ContactUs1 className="btn-4" />
          </div>
        </nav>
      )}
    </header>
  );
}
