import React from "react";
import "./Company.css";
import Paragraph from "../../ExternalCom/Paragraph/Paragraph";
import Heading from "../../ExternalCom/Heading/Headingcom";
import Images4 from "../../Images/newimg4.png";
import Images5 from "../../Images/newimg5.png";
const Company = () => {
  return (
    <section className="section-hr-products">
      <div className="company-con">
        <div className="com-text-box">
          <div className="com-head">
            <Heading level={1} className="com-det">
              Welcome to HR Labs,
              <br />
              where human-centered HR consulting <br />
              meets transformative solutions.
            </Heading>
          </div>
          <div className="com-wrapper">
            <Paragraph className="com-text">
              <li>
                At HR Labs, we believe that people are the heart of every
                organization.
              </li>
            </Paragraph>
          </div>
          <div className="com-wrapper">
            <Paragraph className="com-text">
              <li>
                That's why we're dedicated to providing personalized HR
                consulting services that empower businesses to thrive and grow.
              </li>
            </Paragraph>
          </div>
        </div>
        <div className="com-img-con">
          <div className="com-img1">
            <img src={Images4} alt="" />
          </div>
        </div>
      </div>

      <div className="company-con1">
        <div className="com-img-con1">
          <div className="com-img2">
            <img src={Images5} alt="" />
          </div>
        </div>
        <div className="com-text-box1">
          <div className="com-wrapper">
            <Paragraph className="com-text">
              <li>
                With a focus on humanizing the workplace experience, we tailor
                our solutions to meet the unique needs and challenges of each
                client.
              </li>
            </Paragraph>
          </div>
          <div className="com-wrapper">
            <Paragraph className="com-text">
              <li>
                At HR Labs, we're more than just consultants –{" "}
                <span style={{ color: "#0086D3" }}>
                  we're partners on your journey to success.
                </span>
                Let us help you unlock the full potential of your most valuable
                asset – your people.
              </li>
            </Paragraph>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Company;
